import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Non trovato" />
    <h1>NON TROVATO</h1>
    <p>Questa pagina non esiste.</p>
  </Layout>
)

export default NotFoundPage
